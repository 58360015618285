<template>
  <div class="wrapper fadeIn">
    <b-modal ref="feedback" id="feedback" class="modal-profile" hide-footer size="lg" title="Обратная связь">
      <div class="modal-profile">
        <b-form @submit.prevent="onSubmit" @reset="onReset">
          <b-form-group id="input-group-2" label="Имя" label-for="input-2">
            <b-form-input
                id="input-2"
                v-model="feedbackForm.email"
                placeholder="Email"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Текст обращения" label-for="input-2">
            <b-form-textarea
                id="input-2"
                v-model="feedbackForm.text"
                placeholder="Текст"
                required
            ></b-form-textarea>
          </b-form-group>

          <div class="row mt-5">
            <div class="col-auto">
              <b-button type="submit" variant="success">Отправить</b-button>
            </div>
            <div class="col-auto">
              <b-button @click="close()" type="button" variant="outline-dark">Отменить</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </b-modal>
    <head-component/>
    <section class="main-block">
      <div class="row">
        <div class="col-6">
          <div class="main-text">
            <h1>
              Онлайн-школа Универисариум<br/> Универсариум &mdash; детям
            </h1>
            <p>
              Образовательные онлайн-модули для детей – короткие курсы на разнообразные познавательные тематики<br/>
              Разработаны в сотрудничестве с педагогами ОАНО «Образовательный центр в Хамовниках» (школы Soft)
            </p>
          </div>
        </div>
        <div class="col-6">
          <img src="/images/main.jpg">
        </div>
      </div>
    </section>
    <section class="for-whom-block" style="padding: 100px 130px 100px;">
      <h1><a href="/news">Новости</a></h1>
      <carousel :per-page="2">
        <slide>
          <div class="news-item">
            <h3><a href="/news">20 октября 2021 г. Разрабатываем портал для онлайн-школы</a></h3>
            <img src="https://edu-oko.ru/uploads/Screenshot_5.jpg">
          </div>
        </slide>
        <slide>
          <div class="news-item">
            <h3><a href="/news">22 ноября 2021 г. Съемка лекций для курсов открытого образования</a></h3>
            <img src="https://edu-oko.ru/uploads/2002.png">
          </div>
        </slide>
        <slide>
          <div class="news-item">
            <h3><a href="/news">26 ноября 2021 г. Старт курсов!</a></h3>
            <img src="https://edu-oko.ru/uploads/2003.png">
          </div>
        </slide>
        <slide>
          <div class="news-item">
            <h3><a href="/news">3 декабря 2021 г. К обучению в онлайн-школе присоединились дети из различных стран.</a></h3>
            <p>Участниками курсов стали ребята из школ  «Христо Ботев» (город Севлиево, Болгария), школы «Св. св. Кирил и Методий» (город Кюстендил, Болгария), гимназии города Ельска (Беларусь) и школы №1 (Беларусь).</p>
          </div>
        </slide>
      </carousel>

    </section>
    <section class="for-whom-block" style="padding: 100px 130px 100px;">
      <h1 id="opportunities">Возможности</h1>
      <p>Комплексный образовательный портал предназначен для самостоятельного обучения по образовательным программам дополнительного образования, в том числе изучения русского языка как иностранного.</p>

      <p>Портал предполагает возможность удаленного обучения в интерактивной форме на безвозмездной основе с предоставлением права независимого выбора пользователями времени и места доступа к образовательным и тестовым материалам.</p>

      <p>В ходе прохождения курсов учащиеся взаимодействуют со следующим контентом:</p>
      <ul>
        <li>Видеолекции, разделенные на логически и интонационно законченные смысловые блоки продолжительностью 5-15 минут (с учетом возрастной категории обучающихся и рекомендаций Министерства просвещения Российской Федерации по безопасной работе за компьютером для школьников) и предполагающими систематическое, последовательное устное изложение преподавателем материала.</li>
        <li>Лекционный материал, представленный в виде структурированных текстов, несущих основное содержание модуля, а также элементы визуализации.</li>
        <li>Рабочие тетради, содержащие задания для каждого смыслового блока.</li>
        <li>Контрольно-измерительные материалы. Каждый модуль реализуемых курсов предполагает прохождение теста с автоматизированной проверкой (тестовые вопросы закрытого типа).</li>
        <li>Информационно-методическое обеспечение (списки печатных и иных ресурсов; списки ссылок на электронные ресурсы).</li>
      </ul>
      <p>Обучающиеся могут принять участие в проекте <a href="https://knigovorot.club/about">«Knigovorot» https://knigovorot.club/about</a>. Целью данного проекта является запуск процесса обмена образовательными и методическими материалами в формате Р2Р для обеспечения продвижения навыков и знаний цифровой экономики между Россией и зарубежными странами, собирая и отправляя книги и учебники в те места, где в них действительно нуждаются.</p>
      <p>Родители и дети могут принять участие в мероприятиях, анонсы которых размещены на сайте «Система поддержки русских школ» <a href="https://russchools.org/announcements">https://russchools.org/announcements</a></p>
    </section>
    <section class="users-count">
      <div class="row">
        <div class="col-7">
          <div class="text-wrapper">
            Цикл курсов для детей 6-8 лет:
            <ul style="list-style: none;">
              <li>– как выразительно читать и правильно писать;</li>
              <li>– как устроен окружающий мир;</li>
              <li>– как создать свой кукольный спектакль и цветные рисунки;</li>
              <li>– зачем изучать математику и экономику.</li>
            </ul>
          </div>
        </div>
        <div class="col-5">
          <div class="count-block">
            <h3>1 700 000 +</h3>
            <span>пользователей</span>
            <p>
              <br/>*включая партнерские программы
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="for-whom-block">
      <h1>Для кого:</h1>
      <div class="row">
        <div class="col-12">
          <div class="whom-block">
            <h2>
              Учащихся русских школ за рубежом
            </h2>
            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="86" height="86" rx="43" fill="#FCC6C6"/>
              <path
                  d="M54.8125 35.125H31.1875C30.1436 35.1261 29.1427 35.5414 28.4045 36.2795C27.6664 37.0177 27.2511 38.0186 27.25 39.0625V46.9375C27.2508 47.6334 27.5276 48.3007 28.0197 48.7928C28.5118 49.2849 29.1791 49.5617 29.875 49.5625V58.75C29.8758 59.4459 30.1526 60.1132 30.6447 60.6053C31.1368 61.0974 31.8041 61.3742 32.5 61.375H37.75C38.4459 61.3742 39.1132 61.0974 39.6053 60.6053C40.0974 60.1132 40.3742 59.4459 40.375 58.75V43H37.75V58.75H32.5V46.9375H29.875V39.0625C29.8753 38.7145 30.0137 38.3808 30.2598 38.1348C30.5058 37.8887 30.8395 37.7503 31.1875 37.75H54.8125C55.1605 37.7503 55.4942 37.8887 55.7402 38.1348C55.9863 38.3808 56.1247 38.7145 56.125 39.0625V46.9375H53.5V58.75H48.25V43H45.625V58.75C45.6258 59.4459 45.9026 60.1132 46.3947 60.6053C46.8868 61.0974 47.5541 61.3742 48.25 61.375H53.5C54.1959 61.3742 54.8632 61.0974 55.3553 60.6053C55.8474 60.1132 56.1242 59.4459 56.125 58.75V49.5625C56.8209 49.5617 57.4882 49.2849 57.9803 48.7928C58.4724 48.3007 58.7492 47.6334 58.75 46.9375V39.0625C58.7489 38.0186 58.3336 37.0177 57.5955 36.2795C56.8573 35.5414 55.8564 35.1261 54.8125 35.125Z"
                  fill="black"/>
              <path
                  d="M35.125 33.8125C34.0867 33.8125 33.0716 33.5046 32.2083 32.9277C31.3449 32.3508 30.672 31.5309 30.2746 30.5716C29.8773 29.6123 29.7733 28.5567 29.9759 27.5383C30.1785 26.5199 30.6785 25.5844 31.4127 24.8502C32.1469 24.116 33.0824 23.616 34.1008 23.4134C35.1192 23.2108 36.1748 23.3148 37.1341 23.7121C38.0934 24.1095 38.9133 24.7824 39.4902 25.6458C40.0671 26.5091 40.375 27.5242 40.375 28.5625C40.3735 29.9544 39.82 31.289 38.8357 32.2732C37.8515 33.2575 36.5169 33.811 35.125 33.8125ZM35.125 25.9375C34.6058 25.9375 34.0983 26.0915 33.6666 26.3799C33.235 26.6683 32.8985 27.0783 32.6998 27.558C32.5011 28.0376 32.4492 28.5654 32.5504 29.0746C32.6517 29.5838 32.9017 30.0515 33.2688 30.4187C33.636 30.7858 34.1037 31.0358 34.6129 31.1371C35.1221 31.2384 35.6499 31.1864 36.1295 30.9877C36.6092 30.789 37.0192 30.4526 37.3076 30.0209C37.596 29.5892 37.75 29.0817 37.75 28.5625C37.7492 27.8666 37.4724 27.1993 36.9803 26.7072C36.4882 26.2151 35.821 25.9383 35.125 25.9375Z"
                  fill="black"/>
              <path
                  d="M50.875 33.8125C49.8367 33.8125 48.8216 33.5046 47.9583 32.9277C47.0949 32.3508 46.422 31.5309 46.0246 30.5716C45.6273 29.6123 45.5233 28.5567 45.7259 27.5383C45.9285 26.5199 46.4285 25.5844 47.1627 24.8502C47.8969 24.116 48.8324 23.616 49.8508 23.4134C50.8692 23.2108 51.9248 23.3148 52.8841 23.7121C53.8434 24.1095 54.6633 24.7824 55.2402 25.6458C55.8171 26.5091 56.125 27.5242 56.125 28.5625C56.1235 29.9544 55.57 31.289 54.5857 32.2732C53.6015 33.2575 52.2669 33.811 50.875 33.8125ZM50.875 25.9375C50.3558 25.9375 49.8483 26.0915 49.4166 26.3799C48.985 26.6683 48.6485 27.0783 48.4498 27.558C48.2511 28.0376 48.1992 28.5654 48.3004 29.0746C48.4017 29.5838 48.6517 30.0515 49.0188 30.4187C49.386 30.7858 49.8537 31.0358 50.3629 31.1371C50.8721 31.2384 51.3999 31.1864 51.8795 30.9877C52.3592 30.789 52.7692 30.4526 53.0576 30.0209C53.346 29.5892 53.5 29.0817 53.5 28.5625C53.4992 27.8666 53.2224 27.1993 52.7303 26.7072C52.2382 26.2151 51.571 25.9383 50.875 25.9375Z"
                  fill="black"/>
            </svg>
            <p>Цикл курсов представляет собой серию образовательных онлайн-модулей для детей 6-8 лет, изучающих русский язык. Учащиеся получат сертификат онлайн-школы «Универсариум»</p>
          </div>
        </div>
        <div class="col-12">
          <div class="whom-block">
            <h2>
              Иностранцев, изучающих РКИ
            </h2>
            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="86" height="86" rx="43" fill="#D7C6FC"/>
              <path
                  d="M54.8125 35.125L56.9033 39.0625L61.375 39.6059L58.0938 42.5629L58.75 46.9375L54.8125 44.4766L50.875 46.9375L51.5312 42.5629L48.25 39.6059L52.8438 39.0625L54.8125 35.125Z"
                  fill="black"/>
              <path
                  d="M50.875 61.375H48.25V54.8125C48.248 53.0726 47.5559 51.4046 46.3256 50.1744C45.0954 48.9441 43.4274 48.252 41.6875 48.25H33.8125C32.0726 48.252 30.4046 48.9441 29.1744 50.1744C27.9441 51.4046 27.252 53.0726 27.25 54.8125V61.375H24.625V54.8125C24.6278 52.3767 25.5967 50.0415 27.3191 48.3191C29.0415 46.5967 31.3767 45.6278 33.8125 45.625H41.6875C44.1233 45.6278 46.4585 46.5967 48.1809 48.3191C49.9033 50.0415 50.8722 52.3767 50.875 54.8125V61.375Z"
                  fill="black"/>
              <path
                  d="M37.75 27.25C39.0479 27.25 40.3167 27.6349 41.3959 28.356C42.4751 29.0771 43.3163 30.102 43.813 31.3011C44.3097 32.5003 44.4396 33.8198 44.1864 35.0928C43.9332 36.3658 43.3082 37.5351 42.3904 38.4529C41.4726 39.3707 40.3033 39.9957 39.0303 40.2489C37.7573 40.5021 36.4378 40.3722 35.2386 39.8755C34.0395 39.3788 33.0146 38.5376 32.2935 37.4584C31.5724 36.3792 31.1875 35.1104 31.1875 33.8125C31.1875 32.072 31.8789 30.4028 33.1096 29.1721C34.3403 27.9414 36.0095 27.25 37.75 27.25ZM37.75 24.625C35.9329 24.625 34.1566 25.1638 32.6457 26.1734C31.1348 27.1829 29.9572 28.6178 29.2619 30.2966C28.5665 31.9754 28.3845 33.8227 28.739 35.6049C29.0935 37.3871 29.9686 39.0241 31.2535 40.309C32.5384 41.5939 34.1754 42.469 35.9576 42.8235C37.7398 43.178 39.5871 42.996 41.2659 42.3006C42.9447 41.6053 44.3796 40.4277 45.3891 38.9168C46.3987 37.4059 46.9375 35.6296 46.9375 33.8125C46.9375 31.3758 45.9695 29.0389 44.2465 27.316C42.5236 25.593 40.1867 24.625 37.75 24.625Z"
                  fill="black"/>
            </svg>
            <p>Все желающие могут пройти курсы русского языка как иностранного, разработанные нашими партнерами и размещенные на платформе Универсариум:</p>
            <ul>
              <li><a href="https://universarium.org/course/1442">Слушаем и говорим (РКИ, уровень А1). Поток 1</a></li>
              <li><a href="https://universarium.org/course/1438">Слушаем и говорим (РКИ, уровень А2). Поток 1</a></li>
              <li><a href="https://universarium.org/course/1436">Пишем по-русски (РКИ, уровень А1). Поток 1</a></li>
              <li><a href="https://universarium.org/course/1440">Пишем по-русски (РКИ, уровень А2). Поток 1</a></li>
              <li><a href="https://universarium.org/course/1434">Делаем тест по чтению (РКИ, уровень А1) Поток 1</a></li>
              <li><a href="https://universarium.org/course/1413">Делаем тест по чтению (РКИ, уровень А2) Поток 1</a></li>
              <li><a href="https://universarium.org/course/1398">Делаем тест по лексике и грамматике (РКИ, уровень А1). Поток 1</a></li>
              <li><a href="https://universarium.org/course/1412">Делаем тест по лексике и грамматике (РКИ, уровень А2). Поток 1</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="courses" v-if="courses">
      <h1>Программы обучения</h1>
      <div v-for="course in courses" :key="course.id" class="row lines">
        <div class="col-4">
          <img :src="course.cover.contentPath">
        </div>
        <div class="col-6">
          <div class="education-block">
            <h4>{{ course.name }}</h4>
            <div class="icon-block">
              <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.2857 18.0716H1.71429C1.25963 18.0716 0.823594 17.891 0.502103 17.5695C0.180612 17.248 0 16.8119 0 16.3573V2.643C0 2.18834 0.180612 1.7523 0.502103 1.43081C0.823594 1.10932 1.25963 0.928711 1.71429 0.928711H16.2857C16.7404 0.928711 17.1764 1.10932 17.4979 1.43081C17.8194 1.7523 18 2.18834 18 2.643V6.123L22.6457 2.80585C22.7736 2.71477 22.9241 2.66058 23.0808 2.6492C23.2374 2.63782 23.3941 2.6697 23.5339 2.74134C23.6736 2.81298 23.791 2.92164 23.8732 3.05546C23.9554 3.18927 23.9993 3.3431 24 3.50014V15.5001C23.9993 15.6572 23.9554 15.811 23.8732 15.9448C23.791 16.0786 23.6736 16.1873 23.5339 16.2589C23.3941 16.3306 23.2374 16.3625 23.0808 16.3511C22.9241 16.3397 22.7736 16.2855 22.6457 16.1944L18 12.8773V16.3573C18 16.8119 17.8194 17.248 17.4979 17.5695C17.1764 17.891 16.7404 18.0716 16.2857 18.0716ZM1.71429 2.643V16.3573H16.2857V11.2144C16.2864 11.0574 16.3303 10.9036 16.4125 10.7697C16.4947 10.6359 16.6121 10.5273 16.7518 10.4556C16.8916 10.384 17.0483 10.3521 17.2049 10.3635C17.3616 10.3749 17.5121 10.4291 17.64 10.5201L22.2857 13.8373V5.163L17.64 8.48014C17.5121 8.57123 17.3616 8.62542 17.2049 8.63679C17.0483 8.64817 16.8916 8.6163 16.7518 8.54465C16.6121 8.47301 16.4947 8.36435 16.4125 8.23054C16.3303 8.09672 16.2864 7.9429 16.2857 7.78585V2.643H1.71429Z"
                    fill="#008C8C"/>
              </svg>
              &nbsp;{{course.tasks.videos}} {{course.tasks.videos | pluralize('ru', ['видеолекция', 'видеолекции', 'видеолекций'])}}
            </div>
            <div class="icon-block">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.5415 19.5H1.5415V21H22.5415V19.5Z" fill="#008C8C"/>
                <path
                    d="M19.0915 6.75C19.6915 6.15 19.6915 5.25 19.0915 4.65L16.3915 1.95C15.7915 1.35 14.8915 1.35 14.2915 1.95L3.0415 13.2V18H7.8415L19.0915 6.75ZM15.3415 3L18.0415 5.7L15.7915 7.95L13.0915 5.25L15.3415 3ZM4.5415 16.5V13.8L12.0415 6.3L14.7415 9L7.2415 16.5H4.5415Z"
                    fill="#008C8C"/>
              </svg>
              &nbsp;&nbsp;{{course.tasks.tasks}} {{course.tasks.tasks | pluralize('ru', ['задание', 'задания', 'заданий'])}}
            </div>
            <p>
              {{ course.shortDescription }}
            </p>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-success" @click.prevent="enroll(course.id)" href="#">Начать обучение</a>
        </div>
      </div>
    </section>
    <section class="education">
      <h1>Как проходит обучение</h1>
      <div class="row">
        <div class="col-6">
          <h2>Видеоуроки</h2>
          <p>
            Каждый курс состоит из нескольких коротких видеоуроков продолжительностью 10-15 минут. Смотрите видео в
            удобное для вас время, на компьютере или телефоне.
          </p>
        </div>
        <div class="col-6">
          <img src="/images/video.png"/>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <img src="/images/test.png"/>
        </div>
        <div class="col-6">
          <h2>Закрепление пройденного</h2>
          <p>
            После каждого урока — небольшой тест для проверки полученных знаний: три-четыре вопроса, чтобы закрепить
            пройденный материал.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h2>Финальное тестирование</h2>
          <p>
            Комплексный портал и курсы открытого образования на русском языке разработаны в рамках соглашения о предоставлении из федерального бюджета гранта в форме субсидий на реализацию мероприятий, направленных на полноценное функционирование и развитие русского языка, ведомственной целевой программы «Научно-методическое, методическое и кадровое обеспечение обучения русскому языку и языкам народов Российской Федерации» подпрограммы «Совершенствование управление системой образования» государственной программы Российской Федерации «Развитие образования»
          </p>
        </div>
        <div class="col-6">
          <img src="/images/lecture.jpeg"/>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <img src="/images/cert.png"/>
        </div>
        <div class="col-6">
          <h2>Сертификация</h2>
          <p>
            Для получения сертификата, нужно выполнить финальное тестирование. После сдачи тестирования вы получаете
            сертификат, подтверждающий ваше обучение. Сертификат можно будет распечатать или приложить к своему резюме.
          </p>
        </div>
      </div>
    </section>
    <section style="margin-top: 50px;">
      <p style="font-size: 26px; line-height: 30px; text-align: center;">
        Комплексный портал и курсы открытого образования на русском языке разработаны в рамках соглашения о предоставлении из федерального бюджета гранта в форме субсидий на реализацию мероприятий, направленных на полноценное функционирование и развитие русского языка, ведомственной целевой программы «Научно-методическое, методическое и кадровое обеспечение обучения русскому языку и языкам народов Российской Федерации» подпрограммы «Совершенствование управление системой образования» государственной программы Российской Федерации «Развитие образования»
      </p>
      <div class="row">
        <div class="col-md-6">
          <a target="_blank" href="https://www.edu-oko.ru/"><img src="https://www.edu-oko.ru/templates/oko/images/oko_logo_full.svg"></a>
        </div>
        <div class="col-md-6">
          <a target="_blank" href="https://edu.gov.ru/"><img src="/images/img.png"></a>
        </div>
      </div>
    </section>
    <section class="start">
      <h2>Начните учиться онлайн из любой<br/> точки Мира.</h2>
      <a class="btn btn-success" @click.prevent="enroll()" href="#">Начать обучение</a>
    </section>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import HeadComponent from "../components/HeadComponent";
import {TokenService} from "../services/token.service";
import {eventBus} from "../main";
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "Home",
  components: {HeadComponent, Carousel, Slide},
  data() {
    return {
      courses:  null,
      feedbackForm: {},
      user: null
    }
  },
  methods: {
    enroll(courseId = null) {

      if(!TokenService.getUser()) {
        eventBus.$emit('auth:modal:show')
        return;
      }

      if(!courseId) {
        window.location.href = '/courses'
      }

      ApiService.postJSON(`enrollments/${courseId}`, {
        'isActive': true
      }).then(() => {
        window.location.href = '/course/' + courseId
      });
    },
    showFeedback() {
      this.$refs['feedback'].show()
    },
    close() {
      this.$refs['feedback'].hide()
    },
    onSubmit() {

    },
    onReset() {
      this.feedbackForm = {}
    }
  },
  mounted() {
    ApiService.get('courses', {
      params: {
        isKids: true
      }
    }).then(res => {
      this.courses = res.data
      this.user = TokenService.getUser()
      this.feedbackForm.email = this.user
    })
  }
}
</script>

<style scoped>
.news-item {
  padding: 0 10px;
}
</style>